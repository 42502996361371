// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-blog-paginated-posts-tsx": () => import("./../src/components/blog/paginated-posts.tsx" /* webpackChunkName: "component---src-components-blog-paginated-posts-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-blog-by-author-tsx": () => import("./../src/templates/blog-by-author.tsx" /* webpackChunkName: "component---src-templates-blog-by-author-tsx" */),
  "component---src-templates-blog-by-category-tsx": () => import("./../src/templates/blog-by-category.tsx" /* webpackChunkName: "component---src-templates-blog-by-category-tsx" */),
  "component---src-templates-blog-by-month-tsx": () => import("./../src/templates/blog-by-month.tsx" /* webpackChunkName: "component---src-templates-blog-by-month-tsx" */),
  "component---src-templates-blog-by-tag-tsx": () => import("./../src/templates/blog-by-tag.tsx" /* webpackChunkName: "component---src-templates-blog-by-tag-tsx" */),
  "component---src-templates-blog-by-year-tsx": () => import("./../src/templates/blog-by-year.tsx" /* webpackChunkName: "component---src-templates-blog-by-year-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-page-wrapper-tsx": () => import("./../src/templates/page-wrapper.tsx" /* webpackChunkName: "component---src-templates-page-wrapper-tsx" */)
}

